import React from 'react';

const Mission = () => {
    return (
        <div id='mission' className='md:py-24 py-2 text-white justify-center mx-auto max-w-screen-xl text-center md:px-10 px-5'>
            <div className='mx-auto justify-center'>
                <div>
                    <h1 className='text-base font-semibold text-[#0ec9ac]'>
                        Our Mission
                    </h1>
                    <h1 className='text-white md:text-4xl text-3xl justify-center md:pt-5 pt-3 font-semibold'>
                        6 Steps to Cyber Maturity
                    </h1>
                </div>
                <div className='lg:grid lg:grid-cols-3 mx-auto justify-center md:gap-5 md:pt-10 pt-5'>
                    <div className='py-10 px-6 bg-[#1a1e24] hover:bg-[#0D0D0D] md:mt-0 mt-5'>
                        <img className='w-20 justify-center mx-auto' src='./invisible.png' alt='logo'/>
                        <h1 className='text-lg lg:text-xl font-semibold justify-center py-5'>
                           Visibility
                        </h1>
                        <p className='md:text-base text-[#C3CDD3]'>
                            With continuous scanning of the surface, deep, and dark web, and our human Recon services, we provide our clients with full visibility of their digital exposure to the outside world and provide actions required to reduce their risks.
                        </p>
                    </div>
                    <div className='py-10 px-6 bg-[#1a1e24] hover:bg-[#0D0D0D] md:mt-0 mt-5'>
                        <img className='w-16 justify-center mx-auto' src='./high-risk.png' alt='logo'/>
                        <h1 className='text-lg lg:text-xl font-semibold justify-center py-5'>
                            Mitigating Risk
                        </h1>
                        <p className='md:text-base text-[#C3CDD3]'>
                            By understanding our client’s business DNA and long-term strategy, our Cyber Security Framework of services successfully eliminates cyber security risks while enhancing their overall cyber maturity.
                        </p>
                    </div>
                    <div className='py-10 px-6 bg-[#1a1e24] hover:bg-[#0D0D0D] md:mt-0 mt-5'>
                        <img className='w-16 justify-center mx-auto' src='./cycle.png' alt='logo'/>
                        <h1 className='text-lg lg:text-xl font-semibold justify-center py-5'>
                            Creating Good Cyber Culture
                        </h1>
                        <p className='md:text-base text-[#C3CDD3]'>
                            Our comprehensive Cyber Threat Awareness programs and platforms aim to improve our clients’ understanding of the latest digital-based scams and techniques to heighten staff cyber awareness, and mitigate the risk posed by threats from within and foster a culture of cyber hygiene.
                        </p>
                    </div>
                    <div className='py-10 px-6 bg-[#1a1e24] hover:bg-[#0D0D0D] md:mt-0 mt-5'>
                        <img className='w-16 justify-center mx-auto' src='./ai.png' alt='logo'/>
                        <h1 className='text-lg lg:text-xl font-semibold justify-center py-5'>
                            Intelligence
                        </h1>
                        <p className='md:text-base text-[#C3CDD3]'>
                            Our global network of cyber security and industry experts provides our clients with the latest and most continuous business-related cyber threat intelligence, as well as brand and intellectual property protection.
                        </p>
                    </div>
                    <div className='py-10 px-6 bg-[#1a1e24] hover:bg-[#0D0D0D] md:mt-0 mt-5'>
                        <img className='w-16 justify-center mx-auto' src='./system.png' alt='logo'/>
                        <h1 className='text-lg lg:text-xl font-semibold justify-center py-5'>
                            Monitoring, Automation and Response
                        </h1>
                        <p className='md:text-base text-[#C3CDD3]'>
                            With our Globally Cloud-Based 7x24x365 Security Orchestration, Automation, And Response Service, we provide our clients with a comprehensive package for monitoring and protecting their business.
                        </p>
                    </div>
                    <div className='py-10 px-6 bg-[#1a1e24] hover:bg-[#0D0D0D] md:mt-0 mt-5'>
                        <img className='w-16 justify-center mx-auto' src='./compliances.png' alt='logo'/>
                        <h1 className='text-lg lg:text-xl font-semibold justify-center py-5'>
                            Compliance
                        </h1>
                        <p className='md:text-base text-[#C3CDD3]'>
                            Our cyber security framework and industry standards, including Cyber Essentials, ISO 27001,NIST, PCI, CREST, CIS, CSA, etc, are tailored to you and your business's exact requirements.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mission;
