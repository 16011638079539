import React from 'react';

const Dashboard = () => {
    return (
        <>

            <div className='text-white md:max-w-screen-xl justify-center items-center mx-auto bg-[#0D0D0D]'>
                <div className='md:border md:border-[#0ec9ac] md:mx-3 px-5 md:px-10 md:pt-20 pt-10 md:pb-32 pb-10'>
                    <h1 className='text-base font-semibold text-[#0ec9ac]'>
                        Privacy Risk/Impact Assessment
                    </h1>
                    <h1 className='text-white md:text-4xl text-2xl justify-center pt-5 font-semibold'>
                        Compliance with Federal & Provincial Privacy Requirements
                    </h1>
                    <p className='text-[#C3CDD3] pt-4 max-w-screen-lg'>
                        Once an organization has an initial understanding of its data collection, usage and sharing, the next step is to conduct Privacy Risk Assessments to understand the current and future privacy risks from those practices to the individual consumers and the organization. Organizations can engage in any number of individual or combined reviews in order to evaluate the implications of its business processes on privacy. The purpose of a Privacy Risk Assessment is to provide an early warning system to detect privacy problems, enhance the information available internally to facilitate informed decision-making, avoid costly or embarrassing mistakes in privacy compliance, and provide evidence that an organization is attempting to minimize its privacy risks and problems.                    </p>
                    <p className='text-[#C3CDD3] pt-4 max-w-screen-lg'>
                        Measures compliance with federal and provincial privacy requirements. Identifies risks and provides recommendations.
                    </p>
                    <h1 className='text-white md:text-4xl text-2xl justify-center font-semibold pt-10'>
                        Client Outcomes/Benefits
                    </h1>
                    <p className='text-[#C3CDD3] pt-4 max-w-screen-lg'>
                        Identifies areas of non-compliance and potential regulatory risk exposure. Provides remedial recommendations.                    </p>
                </div>
            </div>
        </>
    );
}

export default Dashboard;