import React from 'react'

export default function Essentials() {
  return (
    <>
    <div className='mx-auto justify-center py-20 bg-[#0D0D0D]'>
        <div className='md:flex md:grid-cols-2 justify-between mx-auto overflow-hidden items-center max-w-screen-xl md:px-10 px-5'>
            <div className=''>
                <svg className='md:w-72 w-60 justify-center' viewBox="0 0 350 85" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Cyber-Essentials-logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group" transform="translate(-0.000000, 0.000000)" fill-rule="nonzero">
                            <path d="M40.0529027,4.47705978 C49.6239333,4.47705978 58.4124055,7.83430442 65.3030767,13.4360198 C62.2682062,16.3779579 59.1700466,19.8736562 56.0959905,24.0223403 L56.0959905,24.0223403 L55.4381874,24.8811389 C54.4088473,26.306379 53.4586872,27.6950745 52.5572532,29.0715885 L52.5572532,29.0715885 L51.8399341,30.1806166 C38.2484271,51.4986455 39.559889,66.7755199 40.0916348,70.2123762 L40.0916348,70.2123762 L40.2112627,70.8786329 L40.6317291,69.7519723 C44.5684777,59.4810903 51.6243503,48.4331308 61.3096895,38.3356495 C65.865436,33.5851536 70.6544972,29.4075198 75.4995463,25.8609242 C78.4408715,31.4340792 80.1058054,37.7842149 80.1058054,44.5238717 C80.1058054,66.6455479 62.1745789,84.5767744 40.0529027,84.5767744 C17.9312265,84.5767744 1.41301112e-14,66.6516387 1.41301112e-14,44.5299625 C1.41301112e-14,22.4082863 17.9373173,4.47705978 40.0529027,4.47705978 Z M18.0652234,28.980227 C20.300536,46.0465642 27.2683767,57.5215746 38.323124,69.0087666 L38.323124,69.0087666 L40.1868996,70.9151776 L40.1990812,70.8725422 C39.9554504,69.7213867 36.8308854,53.2397634 52.3440763,29.3883086 C51.2233746,30.9353641 49.8712237,32.3179689 48.3302589,33.4995782 C44.7915216,36.2099708 40.2721704,37.8422971 35.3386468,37.8422971 C28.71798,37.8422971 22.8464778,34.8943645 19.0884728,30.3323779 C18.7291174,29.8938425 18.3880342,29.4492163 18.0652234,28.980227 Z" id="Combined-Shape" fill="#FDFFFF"></path>
                            <path d="M110.145482,11.9930698 L110.145482,11.9930698 C110.145482,11.9991606 103.683176,2.60719343 96.0940763,0.712964002 C90.648928,-0.64527768 85.4474106,-0.14583455 79.4480022,2.93000424 C79.3992761,2.95436732 79.3505499,2.98482116 79.3079145,3.00918424 C78.5831129,3.38681198 77.8461298,3.8070751 77.0908743,4.25779207 C70.7260198,8.08888632 63.3440067,14.2405639 56.0959905,24.0223403 C55.8767228,24.3146973 55.6574551,24.5826911 55.4381874,24.8811389 C54.4088473,26.306379 53.4586872,27.6950745 52.5572532,29.0715885 C52.484164,29.1751316 52.4232563,29.2847655 52.3501671,29.3883086 C36.8369762,53.2397634 39.9615411,69.7213867 40.2112627,70.8786329 C40.2173535,70.8542699 40.229535,70.8359976 40.2356258,70.8116345 C44.0849923,60.2441487 51.2903731,48.7813198 61.3096895,38.3356495 C77.2675066,21.6956662 96.0879855,12.0844314 110.163755,12.0113421 C110.151573,12.0052513 110.145482,11.9991606 110.145482,11.9930698" id="Path-Copy-13" fill-opacity="0.7" fill="#0ec9ac"></path>
                            <path d="M52.3440763,29.3883086 C51.2233746,30.9353641 49.8712237,32.3179689 48.3302589,33.4995782 C44.7915216,36.2099708 40.2721704,37.8422971 35.3386468,37.8422971 C28.71798,37.8422971 22.8464778,34.8943645 19.0884728,30.3323779 C18.7291174,29.8938425 18.3880342,29.4492163 18.0652234,28.980227 C20.300536,46.0465642 27.2683767,57.5215746 38.323124,69.0087666 C38.536301,69.2280343 38.7555687,69.447302 38.9687456,69.6665697 C39.3768272,70.0807421 39.7727273,70.4949144 40.1868996,70.9151776 C40.1929904,70.902996 40.1929904,70.8908145 40.1990812,70.8725422 C39.9554504,69.7213867 36.8308854,53.2397634 52.3440763,29.3883086" id="Path-Copy-15" fill-opacity="0.5" fill="#0ec9ac"></path>
                            <path d="M292.905123,48.1844244 L303.923326,76.963312 L297.960462,76.963312 L295.737331,71.0004483 L284.639948,71.0004483 L282.422908,76.963312 L276.460045,76.963312 L287.478247,48.1844244 L292.905123,48.1844244 Z M290.194731,55.8770667 C290.033934,56.4666533 289.736705,57.2940234 289.477676,57.9818419 L286.454998,65.9451093 L293.934463,65.9451093 L291.096165,58.4656439 C290.809898,57.7286608 290.401817,56.6140499 290.194731,55.8770667 Z" id="Shape" fill="#FFFFFF"></path>
                            <path d="M235.877245,12.1027037 C243.113079,12.1027037 245.494571,16.3784241 245.494571,21.1048615 C245.494571,24.2213867 243.788141,27.7925853 240.342951,29.237259 L239.903244,29.4065809 L245.494571,40.8755005 L239.245441,40.8755005 L233.8612,30.143564 L228.062787,30.143564 L228.062787,40.8694098 L222.514096,40.8694098 L222.514096,12.1027037 L235.877245,12.1027037 Z M235.956425,17.3590381 L228.062787,17.3590381 L228.062787,24.8811389 L235.956425,24.8811389 C238.794724,24.8811389 239.738793,23.0721802 239.738793,21.0987708 C239.738793,19.3651854 238.983633,17.5197244 236.3154,17.3689457 L235.956425,17.3590381 Z" id="Shape" fill-opacity="0.7" fill="#0ec9ac"></path>
                            <path d="M184.769595,12.0966129 C191.798343,12.0966129 194.100654,15.9216164 194.100654,19.8257999 C194.100654,22.2499263 193.11395,24.5096019 190.604552,25.7033928 C193.857024,26.8971837 195.087359,29.8146625 195.087359,32.3240596 C195.087359,36.28837 192.664522,40.6458853 185.886331,40.8667413 L185.342127,40.8755005 L172.143429,40.8755005 L172.143429,12.0966129 L184.769595,12.0966129 Z M184.477238,28.5782362 L177.69212,28.5782362 L177.69212,35.6922554 L185.628393,35.6922554 C188.588508,35.6922554 189.447306,33.8406613 189.453397,32.3179689 C189.453397,30.5383764 188.609538,28.7134761 184.90117,28.585396 L184.477238,28.5782362 Z M184.842684,17.2737673 L177.69212,17.2737673 L177.69212,23.4010818 L184.105701,23.4010818 C187.065815,23.4010818 188.466692,22.1646555 188.460641,20.3130615 C188.460641,18.8330044 187.881978,17.2737673 184.842684,17.2737673 Z" id="Shape" fill-opacity="0.7" fill="#0ec9ac"></path>
                            <polygon id="Path" fill-opacity="0.7" fill="#0ec9ac" points="198.540826 40.8694098 219.054538 40.8694098 219.054538 35.6069846 204.089517 35.6069846 204.089517 28.663507 212.391236 28.663507 212.391236 23.4010818 204.089517 23.4010818 204.089517 17.3590381 218.189649 17.3590381 218.189649 12.0966129 198.540826 12.0966129"></polygon>
                            <polygon id="Path" fill-opacity="0.7" fill="#0ec9ac" points="153.968572 40.8694098 159.474628 40.8694098 159.474628 29.6502117 170.121293 12.0966129 163.829528 12.0966129 156.715509 23.8517988 149.60149 12.0966129 143.309724 12.0966129 153.95639 29.6502117 153.95639 40.8694098"></polygon>
                            <path d="M132.626514,41.3201267 C137.846304,41.3201267 141.056139,38.402648 142.944278,34.2061075 L137.846304,32.2814243 C136.530697,34.4619199 134.96537,35.9785216 132.626514,35.9785216 C128.186343,35.9785216 126.298204,30.9231826 126.298204,26.4830113 C126.298204,22.2499263 127.820897,16.9875011 132.626514,16.9875011 C135.135911,16.9875011 136.488062,18.2239274 137.931575,20.3983322 L142.907734,18.1386566 C141.592127,14.8922763 138.138661,11.6458959 132.632605,11.6458959 C123.709627,11.6458959 120.548481,19.9110706 120.548481,26.4891021 C120.536336,33.5970305 123.624356,41.3201267 132.626514,41.3201267" id="Path" fill-opacity="0.7" fill="#0ec9ac"></path>
                            <path d="M338.537171,77.4566644 C344.372129,77.4566644 348.940206,74.2894641 348.940206,68.6981373 C348.940206,62.4916428 343.555965,60.6826842 339.280245,59.2452625 C336.441946,58.3011932 334.060455,57.5215746 334.060455,55.712616 C334.060455,53.9462927 335.461332,52.959588 337.885459,52.959588 C340.559307,52.959588 342.039364,54.2751943 342.818982,55.9988821 L347.795141,54.3543743 C346.729257,50.5720062 343.35497,47.6971628 338.21436,47.6971628 C332.007866,47.6971628 328.469128,51.3150801 328.469128,55.6334359 C328.469128,61.3465781 333.317381,62.9484506 337.513922,64.3493276 C340.638487,65.3786677 343.348879,66.2801017 343.348879,68.7042281 C343.348879,71.0065391 341.497285,72.20033 338.701622,72.20033 C335.905959,72.20033 333.646283,70.348736 332.409857,67.6809788 L327.354518,69.5752082 C328.999025,74.4539149 333.110295,77.4566644 338.537171,77.4566644" id="Path" fill="#FFFFFF"></path>
                            <polygon id="Path" fill="#FFFFFF" points="306.061186 76.963312 325.502924 76.963312 325.502924 71.6217069 311.609877 71.6217069 311.609877 48.1844244 306.061186 48.1844244 306.061186 76.963312"></polygon>
                            <polygon id="Path" fill="#FFFFFF" points="268.980579 76.963312 274.52927 76.963312 274.52927 48.1844244 268.980579 48.1844244"></polygon>
                            <polygon id="Path" fill="#FFFFFF" points="257.469024 53.5321203 265.691563 53.5321203 265.691563 48.1905152 243.697793 48.1905152 243.697793 53.5321203 251.920333 53.5321203 251.920333 76.963312 257.469024 76.963312"></polygon>
                            <path d="M240.615864,76.963312 L240.615864,48.1844244 L235.109808,48.1844244 L235.109808,64.7513185 C235.109808,65.2873062 235.152443,66.2740109 235.231623,66.9318141 C234.902722,66.2313755 234.366734,65.2873062 234.037832,64.7513185 L222.897814,48.1844244 L217.678025,48.1844244 L217.678025,76.963312 L223.18408,76.963312 L223.18408,60.1893318 C223.18408,59.5741641 223.141445,58.7092747 223.062265,58.0088362 C223.391167,58.7092747 223.927154,59.6959795 224.256056,60.1893318 L235.68234,76.963312 L240.615864,76.963312 Z" id="Path" fill="#FFFFFF"></path>
                            <polygon id="Path" fill="#FFFFFF" points="193.66821 76.963312 214.181923 76.963312 214.181923 71.7008869 199.216901 71.7008869 199.216901 64.7513185 207.51862 64.7513185 207.51862 59.4888933 199.216901 59.4888933 199.216901 53.4468496 213.317033 53.4468496 213.317033 48.1844244 193.66821 48.1844244"></polygon>
                            <path d="M179.98225,77.4566644 C185.817207,77.4566644 190.385285,74.2894641 190.385285,68.6981373 C190.385285,62.4916428 185.001044,60.6826842 180.725324,59.2452625 C177.887025,58.3011932 175.505534,57.5215746 175.505534,55.712616 C175.505534,53.9462927 176.90032,52.959588 179.330537,52.959588 C182.004385,52.959588 183.484442,54.2751943 184.264061,55.9988821 L189.24022,54.3543743 C188.174335,50.5720062 184.800049,47.6971628 179.659439,47.6971628 C173.452944,47.6971628 169.914207,51.3150801 169.914207,55.6334359 C169.914207,61.3465781 174.76246,62.9484506 178.959,64.3493276 C182.083565,65.3786677 184.793958,66.2801017 184.793958,68.7042281 C184.793958,71.0065391 182.942364,72.20033 180.146701,72.20033 C177.351037,72.20033 175.091362,70.348736 173.854935,67.6809788 L168.799596,69.5752082 C170.444104,74.4539149 174.555374,77.4566644 179.98225,77.4566644" id="Path" fill="#FFFFFF"></path>
                            <path d="M155.521718,77.4566644 C161.356675,77.4566644 165.924753,74.2894641 165.924753,68.6981373 C165.924753,62.4916428 160.540512,60.6826842 156.264792,59.2452625 C153.426493,58.3011932 151.045002,57.5215746 151.045002,55.712616 C151.045002,53.9462927 152.439788,52.959588 154.870006,52.959588 C157.543854,52.959588 159.023911,54.2751943 159.803529,55.9988821 L164.779688,54.3543743 C163.713803,50.5720062 160.339517,47.6971628 155.198907,47.6971628 C148.992413,47.6971628 145.453675,51.3150801 145.453675,55.6334359 C145.453675,61.3465781 150.301928,62.9484506 154.498469,64.3493276 C157.623034,65.3786677 160.333426,66.2801017 160.333426,68.7042281 C160.333426,71.0065391 158.481832,72.20033 155.686169,72.20033 C152.890505,72.20033 150.63083,70.348736 149.394403,67.6809788 L144.339064,69.5752082 C145.983572,74.4539149 150.094842,77.4566644 155.521718,77.4566644" id="Path" fill="#FFFFFF"></path>
                            <polygon id="Path" fill="#FFFFFF" points="121.565676 76.963312 142.079389 76.963312 142.079389 71.7008869 127.114367 71.7008869 127.114367 64.7513185 135.416087 64.7513185 135.416087 59.4888933 127.114367 59.4888933 127.114367 53.4468496 141.2145 53.4468496 141.2145 48.1844244 121.565676 48.1844244 121.565676 76.963312"></polygon>
                        </g>
                    </g>
                </svg>
            </div>
            <div className='max-w-screen-md mx-auto md:text-left pt-5'>
                <p className='md:text-lg text-[#C3CDD3] pt-4'>
                    We are thrilled to announce that Blackstone Cyber Security has partnered up  with the Cyber Secuirty firm which have the  Cyber Security Essentials Certification. This achievement underscores our unwavering commitment to safeguarding our systems and data, further solidifying our position as a trusted provider of managed security services.
                </p>
                <p className='md:text-[12px] text-[#aaaaaa] pt-4 text-[10px]'>
                Organisations that undertake Cyber Essentials are encouraged to recertify at least once a year and, where appropriate, progress their security.
                </p>
            </div>
        </div>
    </div>
    </>
  )
}
