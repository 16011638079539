import React from 'react';

const Ourservices = () => {
    return (
        <>
        <div className='justify-center mx-auto items-center pb-20 bg-[#0D0D0D]'>
            <div className='text-white mx-auto justify-center items-center md:px-10 px-2 max-w-screen-2xl'>
                <div className='justify-center py-5 items-center'>
                    <div className='px-4'>
                        <div className='md:flex md:flex-row gap-3 text-[#C3CDD3]'>
                            <div className='bg-[#1a1e24] hover:bg-[#0D0D0D] py-5 px-5 md:w-80 md:mt-0 mt-5'>
                                <img className='md:w-14 w-12' src='./icons8-requirements-100.png' alt='logo'/>
                                <h1 className='text-lg lg:text-xl font-semibold justify-center lg:pt-5 pt-3'>
                                    Compliance Services
                                </h1>
                                <p className='pt-2 text-sm md:text-base'>
                                    Achieves and enforce compliance with various standards effciently and with minimal overhead.
                                </p>
                            </div>
                            <div className='bg-[#1a1e24] hover:bg-[#0D0D0D] py-5 px-5 md:w-80 md:mt-0 mt-5'>
                                <img className='md:w-14 w-12' src='./database.png' alt='logo'/>
                                <h1 className='text-lg lg:text-xl font-semibold justify-center lg:pt-5 pt-3'>
                                    Network Security
                                </h1>
                                <p className='pt-2 text-sm md:text-base'>
                                    Simulate real-world cyberattacks on your corporate network and secure your assets.
                                </p>
                            </div>
                            <div className='bg-[#1a1e24] hover:bg-[#0D0D0D] py-5 px-5 md:w-80 md:mt-0 mt-5'>
                                <img className='md:w-14 w-12' src='./icons8-simulation-100.png' alt='logo'/>
                                <h1 className='text-lg lg:text-xl font-semibold justify-center lg:pt-5 pt-3'>
                                    Virtual CISO
                                </h1>
                                <p className='pt-2 text-sm md:text-base'>
                                    An outsourced Chief Information Security Officer (CISO) service provides a wide reange of skills and experience at your disposal.
                                </p>
                            </div>
                            <div className='bg-[#1a1e24] hover:bg-[#0D0D0D] py-5 px-5 md:w-80 md:mt-0 mt-5'>
                                <img className='md:w-14 w-12' src='./icons8-mobile-id-verification-100.png' alt='logo'/>
                                <h1 className='text-lg lg:text-xl font-semibold justify-center lg:pt-5 pt-3'>
                                    Application Security
                                </h1>
                                <p className='pt-2 text-sm md:text-base'>
                                    Conduct exper-led penetration testing to identify valnerabilities in your applications.
                                </p>
                            </div>
                            <div className='bg-[#1a1e24] hover:bg-[#0D0D0D] py-5 px-5 md:w-80 md:mt-0 mt-5'>
                                <img className='md:w-14 w-12' src='./icons8-infinity-100.png' alt='logo'/>
                                <h1 className='text-lg lg:text-xl font-semibold justify-center lg:pt-5 pt-3'>
                                    DevSecOps
                                </h1>
                                <p className='pt-2 text-sm md:text-base'>
                                    Adopt DevSecOps to go faster and innovate, while always staying secure!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Ourservices;
