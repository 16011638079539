import Dashboard from './Dashboard';
import About from "./About";
import Group from "./Group";
import Discover from "./Discover";
import Footer from "./Footer";
import Business from "./Business";
import Mission from "./Mission";
import Framework from "./Framework";
import Tailored from "./Tailored";
import Risks from "./Risks";
import Collaboration from "./Collaboration";
import Scertificate from "./Scertificate";
import Partners from "./Partners";
import Header from "./Nav"
import Essentials from './Essentials';


function Home() {
  
  return (
    <>
    <Header/>
    <Dashboard/>
    <About/>
    <Business/>
    <Group/>
    <Mission/>
    <Framework/>
    <Tailored/>
    <Risks/>
    <Collaboration/>
    <Essentials/>
    <Partners/>
    <Scertificate/>
    <Discover/>
    <Footer/>
   </>
  );
}

export default Home;
