import React from 'react';

const About = () => {
    return (
        <div id='company' className='md:px-10 px-3'>
            <div className='text-white mx-auto md:py-28 pt-10 max-w-screen-xl justify-center relative'>
                <div className='lg:grid lg:grid-cols-2 justify-center py-5 gap-20 items-center'>
                    <div className='opacity-50 rounded-md shadow-lg'>
                        <img className='hidden md:block lg:block w-full' src='./female-hacker-wearing-a-hoodie-using-a-dangerous-virus-e1615279123329.jpg' alt='Images'/>
                        <img className='block md:hidden lg:hidden' src='./female-hacker-wearing-a-hoodie-using-a-dangerous-virus-e1615279123329 (1).jpg' alt='Images'/>
                    </div>
                    <div className='px-4 md:pt-0 pt-10'>
                        <h1 className='text-base font-semibold text-[#0ec9ac]'>
                            Welcome To Our Company
                        </h1>
                        <h1 className='text-white lg:text-4xl text-3xl justify-center md:pt-5 pt-3 font-semibold'>
                            Delivering Specialist Solutions To Combat The Global Cyber Threat
                        </h1>
                        <p className='md:pt-10 pt-5 md:text-lg text-[#9aa5aa]'>
                            Blackstone Security in partnership with Cyber is a renowned cyber company trusted by leadership teams for cyber intelligence, strategy and operational capability. We pride ourselves on taking global companiess from a reactive, technology-based approach to embedding cyber protection at the very core of their organisation by integrating it with their people and processes.
                        </p>
                        <div className='border-b border-[#097061] py-3'/>
                        <p className='pt-5 md:text-lg text-[#9aa5aa]'>
                            Innovation and change are in our DNA. We are fortunate to work with key innovators in this industry to offer some of the most rigorous and advanced cyber security solutions on the market.
                        </p>
                        <div className='border-b border-[#097061] py-3'/>
                        <img className='pt-10 w-14' src='./cloud-150x150.png' alt='logo'/>
                        <h1 className='md:text-2xl text-lg font-semibold pt-8'>
                            Specialist Cyber Services
                        </h1>
                        <p className='pt-5 md:text-lg text-[#9aa5aa]'>
                            Our Cyber Security Operations Centre of Excellence, owned and operated in-house 24/7, has fully qualified security professionals who provide the most advanced AI-driven cyber services in the market.
                        </p>
                        <div className='fourth flex gap-3 pt-10'>
                            <a 
                                href="/contact" className=" bg-[#0ec9ac] shadow-lg hover:bg-opacity-80 text-white font-semibold items-center relative md:h-12 h-10 md:text-sm text-xs duration-200 px-6 flex rounded-sm">
                                Complimentary Vulnerability Assessment
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
