import React from 'react'

export default function Risks() {
  return (
    <>
      <div className='hidden justify-center text-center md:block lg:block items-center overflow-hidden mx-auto w-full'>
        <img className='hidden md:block lg:block justify-center text-center mx-auto' src='./Cyber_risk.jpg' alt='Logo'/>
      </div>
      <div className='Riskes block md:hidden lg:hidden items-center overflow-hidden mx-auto py-10 mt-10'>
        <div className='md:px-10 px-5 justify-center text-center'>
            <div className='mx-auto overflow-hidden text-[#fff] max-w-screen-md'>
                <h1 className='md:text-5xl py-2 text-2xl'>
                  "One of the main cyber-risks is to think they don't exist. <br/> The other is to try to treat all potential risks."
                </h1>
            </div>
        </div>
      </div>
    </>
  )
}