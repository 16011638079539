import React from 'react';

const Dashboard = () => {
    return (
        <>
        <div className="Pharma items-center duration-500 mx-auto text-[#B7BDC6] md:px-10 px-5 xl:py-60 md:py-44 pt-24 pb-14">
            <div className="max-w-screen-xl mx-auto">
                <div className="items-center lg:grid lg:grid-cols-2">
                    <div className="items-center duration-500 lg:px-8 mx-auto">
                        <div className="items-center mx-auto md:pt-0 pt-5">
                            <div className="second flex flex-row xl:text-7xl lg:text-5xl text-4xl md:text-5xl items-center duration-300 text-[#fff]">
                                <h1>
                                    We Provide <h1 className='text-[#0ec9ac]'>Cyber Solutions</h1>For Your Security
                                </h1>
                                
                            </div>
                        </div>
                        <p className="third md:pt-5 pt-3 md:text-[#C3CDD3] text-white max-w-screen-md md:text-lg tracking-wider">
                            Blackstone's world-class cybersecurity services help to provide tailored cyber solutions that comply with your specific requirements.
                        </p>
                        <div className='fourth flex gap-3 pt-10'>
                            <a 
                                href="/contact" className="bg-[#0ec9ac] shadow-lg hover:bg-opacity-80 text-white font-semibold items-center relative md:h-12 h-10 md:text-sm text-xs duration-200 px-6 flex rounded-sm">
                                Get Free Assessment
                            </a>
                        </div>
                    </div>
                    <div className='fourth hidden md:block lg:block'>
                        <img src='./security.png' alt='Images'/>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Dashboard;